body {
	font-family: var(--font-family);
	font-weight: normal;
	background: var(--surface-ground);
	color: var(--text-color);
	/* padding: 1rem; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.card {
	background: var(--surface-card);
	padding: 2rem;
	border-radius: 10px;
	margin-bottom: 1rem;
}

.btn-user {
	padding: 0.5rem;
	border-radius: 10px;
	margin: 1rem;
}
