:root {
	--button: #28825a;
	--green1: #28825a;
	--green2: #32aa78;
	--green3: #3cbe8c;
	--green4: #50d2a0;
	--text1: #756215;
	--text2: #5e8093;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Prompt', sans-serif;
}

.myCustomButton {
	background-color: var(--green1);
}

.btn-menu {
	background-color: var(--green1);
}

.menu-icons {
	object-fit: contain;
}

.icons {
	padding-right: 5px;
	font-size: 25px;
}

.img-user img {
	border-top-right-radius: 60%;
	border-top-left-radius: 60%;
}

.nav {
	padding-top: 5px;
}

.text-color-1 {
	color: var(--green1) !important;
}

.text-color-2 {
	color: var(--green2) !important;
}

.text-color-3 {
	color: var(--green3) !important;
}

.text-color-4 {
	color: var(--green4) !important;
}

.text-color-5 {
	color: var(--text1) !important;
}

.text-color-6 {
	color: var(--text2) !important;
}

.bg-color-1 {
	background-color: var(--green1);
}

.bg-color-2 {
	background-color: var(--green2);
}

.bg-color-3 {
	background-color: var(--green3);
}

.bg-color-4 {
	background-color: var(--green4);
}

.content-interest {
	padding: 15px 100px;
}

.bg-image {
	background: url(../src/lib/assets/images/main_bg.png) no-repeat;
}

.bg-image-login {
	background: url(../src/assets/images/skillmeo_bg.png) no-repeat;
	background-size: cover;
}


.bg-transparent {
	background: transparent;
}

.bg-gray {
	background-color: #e6e7e9;
}

.content-grid {
	display: flex;
	border-bottom: 0.5px solid #28825a;
	/* border: 2px solid black; */
}

.column-side {
	justify-content: center;
	text-align: center;
	background-color: #e6f7eb;
	background-color: #d7ded9;
	border-right: 0.5px solid var(--green1);
}

.column-content {
	width: 100%;
	/* border: 2px solid red; */
	justify-content: center;
}

.content-info {
	justify-self: center;
	width: 80%;
	padding: 10px;
	/* border: 2px solid blue; */
}

.header-paragraph-group {
	margin-left: 50px;
	margin-right: 50px;
}

.profile-group {
	margin-right: 50px;
	padding: 20px;
	padding-bottom: 0%;
}

.myinfo-group {
	min-width: 50%;
	padding: 10px;
	background-image: linear-gradient(to bottom right, #cee3d7 1%, #fff 80%, #fff 20%);
	border: 1px solid black;
}

.cert-content-info {
	justify-self: center;
	width: 80%;
}

.user-content-info {
	justify-self: center;
	padding: 10px;
}

.border-avatar {
	border: 4px solid var(--green2);
}

.avatar-text-upper {
	margin-top: -30px;
}

.frame-setting {
	overflow: hidden;
	display: inline-block;
	position: relative;
	height: 107px;
	width: 107px;
	/* width: 55%; */
	/* height: 150px; */
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	margin-top: 27%;
	margin-left: 24.4%;
	/* margin-bottom: -8%; */
}

/* .frame-setting {
  overflow: hidden;
  display: inline-block;
  position: relative;
  height: 107px;
  width: 107px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-top: 27%;
  margin-left: 24.4%;
} */

.cert-group {
	background-image: linear-gradient(to bottom right, #cee3d7 1%, #fff 80%, #fff 20%);
}

.myinfo-group-hidden {
	display: none;
}

.row-content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.user-box {
	padding-left: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.user-box-text {
	font-weight: bold;
}

.user-manage {
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.user-manage > div {
	margin-right: 30px;
}

.table-box {
	margin-top: 20px;
	padding: 10px 10px 0px 10px;
}

.table-box-user-text {
	padding-left: 10px;
	margin-bottom: 15px;
}

.sortable-header {
	position: relative;
}

.sort-icon {
	position: absolute;
	top: 50%;
	right: 5px;
	transform: translateY(-50%);
}

.row-container {
	display: flex;
	align-items: center;
}

.active-status {
	background-color: blue;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	margin-right: 10px;
}

.light-green-background {
	background-color: lightgreen;
	padding: 5px 10px;
	border-radius: 5px;
}

.active-status-only {
	background-color: blue;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	margin-right: 10px;
}

.home-container {
	display: flex;
	background-color: #d8e9df;
}

.thumbnail-content {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/* border: 2px solid yellow; */
	width: 80%;
}

.icons-modal {
	padding-right: 10px;
	font-size: 30px;
	color: var(--green1);
}

.icons-modal-delete {
	font-size: 15px;
	color: var(--green1);
}

.row-content {
	padding-top: 20px;
}

.goal-content {
	padding-top: 5px;
	display: flex;
	flex-direction: column;
}

.education-content {
	padding-top: 5px;
	display: flex;
	flex-direction: column;
}

.address-content {
	display: flex;
	padding-top: 10px;
}

.interest-content {
	padding-top: 5px;
}

#main-slider {
	max-width: 100%;
	overflow: hidden;
}

.main-slider-container {
	align-items: center;
	width: 600px;
	margin-top: 20px;
	margin-bottom: 30px;
}

.main-slider-container-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#thumbnail-slider-list.splide__list {
	justify-content: center;
	align-items: center;
}

.slide-content {
	justify-content: center;
	align-items: center;
}

.spinner-loading {
	justify-content: center;
	text-align: center;
	padding: 150px 0px;
}

.pagination-item {
	margin-left: 5px;
}

.page-link {
	color: black !important;
	background-color: #fff;
}

.page-link:hover {
	background-color: #cae5d6 !important;
}

.active > .page-link,.page-link.active {
	background-color: var(--green1) !important ;
	color: white !important;
	border-color: var(--green1) !important;
}

#thumbnail-slider {
	padding-left: 0%;
	padding-right: 0%;
}

.footer-left-image {
	position: relative;
	bottom: 0;
	left: 0;
	max-width: 300px;
}

.footer-right-image {
	position: relative;
	bottom: 0;
	right: 0;
	max-width: 400px;
}

.p-dialog {
	transition: transform 1s ease-in-out;
}

.p-dialog-title {
	text-align: center;
	margin-left: 40px;
}

.p-field {
	margin: 5px 10px;
}

.p-button.p-fileupload-choose {
	background-color: var(--green2);
	padding: 5px 10px;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
	background-color: var(--green2);
}

.p-tabview .p-tabview-nav { 
	display: flex;
	justify-content:space-around;
	padding-left: 0px;
	text-decoration: none;

}

.p-tabview-title {
	line-height: 2;
	color: var(--text1);

}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	text-decoration: none !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: #ffffff;
    border-color: var(--green2);
    color: var(--text1);
	text-decoration: none;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
	background-color: #28825a;
}

.p-galleria .p-galleria-thumbnail-container {
	background-color: gainsboro;
	border-radius: 20px;
}

.p-datepicker-month.p-link {
	margin-right: 10px;
}

.p-tooltip {
   padding: 10px;
}

.p-tooltip-text {
	padding: 10px 10px;
}

.p-tooltip-arrow {
	position: absolute;
	font-size: 30px;
	margin-top: -6px;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon.p-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon.p-icon {
	width: 4rem !important;
	height: 4rem !important;
	color: var(--green1);
}

.p-galleria-item-img-container {
    position: relative;
}

.p-galleria-item-close {
    position: absolute;
    top: 55px;
    right: 10px;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.p-galleria-item-img-container:hover .p-galleria-item-close {
    opacity: 0.7;
}

.galleria-delete-icon {
	font-size: 2rem;
	color: red;
}

svg.p-icon.p-galleria-thumbnail-prev-icon {
	width: 4rem !important;
	height: 4rem !important;
	color: var(--green1)
}

svg.p-icon.p-galleria-thumbnail-next-icon {
    width: 4rem !important;
	height: 4rem !important;
	color: var(--green1)
}

.p-fileupload.p-fileupload-advanced.p-component {
    width: 15rem;
}

.p-badge.p-badge-warning {
	display: none;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
	margin: 20px;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
	margin-left: 20px;
} 

.confirm-dialog {
    justify-content: center;
	align-items: center;
}

.confirm-dialog .pi-exclamation-triangle {
    color: red;
}

.profile-image-select {
	text-align: center ;
} 

.profile-image-select .p-fileupload.p-fileupload-advanced.p-component {
	margin-left: 17px;
	width: 22.8rem;
}

.border-avatar_ {
	margin-top: 20px;
	margin-bottom: -10px;
	clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 85%); 
	overflow: hidden; 
    
}


.hidden-profile-div {
	width: 300px;
	height: 300px;
}

.profile-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.btn-login {
	
	height: 45px;
	background-color: #28825a !important;
	border: none !important;
	outline: none;
	border-radius: 6px;
	cursor: pointer;
	font-size: 0.7em;
	color: #fff;
	font-weight: 500;
	margin-left: 25px;
	margin-right: 25px;
}

.container-login {
	min-height: 100vh

}

.footer-login {
	width: 100%;
	opacity: 0.8; 
	margin-top: auto;
}


.font-h3 {
	font-size: clamp(1px, 5vw, 20px);
}

#target-date > div > div > div > input {
	border: 1px solid #ced4da;
	border-radius: 6px;
	padding: 5px;
	background: #ffffff;
	color: #495057;
	appearance: none;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

#target-date > div > div > div > input:enabled:hover {
    border-color: #6366F1;
} 

#target-date > div > div > div > input:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
    border-color: #6366F1;
}

#birthday-input > div > div > div > input {
	border: 1px solid #ced4da;
	border-radius: 6px;
	padding: 5px;
	background: #ffffff;
	color: #495057;
	appearance: none;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

#birthday-input > div > div > div > input:enabled:hover {
    border-color: #6366F1;
} 

#birthday-input > div > div > div > input:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
    border-color: #6366F1;
}

.prev-birthday-btn {
	border : none ;
	background-color: white;
	width: 20px;
	border-radius:	80%;
}

.next-birthday-btn {
	border : none ;
	background-color: white;
	width: 20px;
	border-radius: 80%;
}

.next-birthday-btn:hover {
	background-color: #ced4da;
}

.prev-birthday-btn:hover {
	background-color: #ced4da;
}

.month-birthday-select {
	border : 1px solid #ced4da;
}

.year-birthday-select {
	border :  1px solid #ced4da;
}

.p-datatable-wrapper {
	overflow: visible !important;
}

.personal-goal-content {
	overflow: visible;
}

.wrapper {
	width: 400px;
	height: 400px;
	background: transparent;
	border: 2px solid rgba(255, 255, 255, 0.5);
	border-radius: 20px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
	right: 0;
	overflow: hidden;
	transition: transform 0.5s ease, height 0.2s ease;
}

.fa-users {
	color:#28825a;
	margin-right: 20px;
	font-size: clamp(1rem, 8vw, 3.5rem);
}

.admin-user-icon {
	color:#28825a;
	margin-left: 10px;
	font-size: clamp(1rem, 8vw, 3.5rem);
}

.p-dialog .p-dialog-header .p-dialog-title{
	font-weight: 500;
    font-size: 1.35rem;
}

@media (max-width: 767px) {
	.nav-menu {
		flex-direction: row;
	}

	.nav-link {
		font-size: 14px;
		padding: 8px 0;
	}

	.column-content {
		width: 100%;
		padding-left: 0;
	}

	.thumbnail {
		max-width: 100%;
		height: auto;
	}

	.container-login,.container-footer-login {
	  display: flex;
	  flex-direction: column;
	}

}


@media screen and (max-width: 768px) {
	/* Adjust styles for smaller screens */
	.container-login {
	  height: auto; /* or any other value suitable for smaller screens */
	}
	

}

@media (min-width: 1100px) {
	/* Styles for desktops */
	.footer-login {
		position: fixed;
		bottom: 0;
	}
	
	.skillmeo-login-bg {
		display: flex;
	}

	.footer-image-right {
		display: flex;
		margin-top: 20px;
	}
	.text-login {
		margin-top: 5rem;
	}

}

@media (max-width: 1100px) {

	.text-login {
		margin-top: 5rem;
	}
}

@media (max-width: 1300px) {
	
	.skillmeo-login-bg {
		display: none;
	}
}

