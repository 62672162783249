:root {
 --green1:#28825A;
 --green2:#32AA78;
 --green3:#3CBE8C;
 --green4:#50D2A0;
}

.swal2-popup .form-group label {
    text-align: left;
    display: block;
  }
   
.header-cert {
    padding: 0px 100px;
    background: url(../../../public/shared/main_bg.png);
    font-weight: 600;
}

#dropdown-basic {
  border: 1px solid black !important;
  text-align: left;
  width: 200px;
}

.dropdown-menu {
  --bs-dropdown-min-width: 14rem !important;
}

/* .dropdown-content-cert{
  background-color: gray;
} */

.btn-create {
  padding-left: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
 
}

.dropdown-btn {
   display: flex;
   justify-self: flex-end;
   padding-right: 20px;
   margin-top: 15px;
}

#dropdown-basic {
  margin-right: 10px;
  
}

.img-group {
  margin: 100px;
}

.g-4 {
  size: 100px;
}

.social-icons {
  font-size: 30px;
}

.img-position {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-1 { 
  width: 50%;
}

.item-2 {
  width: 50%;
}

.item-content {
  display: flex;
}

.nav-menu {
  text-decoration: none;
  justify-content: center;
  
}

#root > div.footer.container {
  justify-content: space-between;
}

.footer-item-right {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  text-align: end;
}

.footer-item-left {

  margin: 0 0 0 0;
 padding: 0 0 0 0;
  text-align: start;
}

.custom-dialog {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-dialog .p-dialog-content {
  padding: 5px 20px; 
}

.p-button { 
  border: none;
  box-shadow: none;
}

.p-button-upload {
  padding: 5px 15px;
  color: white;
  margin-bottom: 5px;
  background-color: var(--green2);
  border-radius: 5px;
}

.upload-cert-btn {
  padding: 5px 15px;
  color: white;
  margin-bottom: 5px;
  background-color: var(--green2);
  border-radius: 5px;
}

.header-upload-title {
  margin-top: 5px;
}

.p-button-secondary {
  padding: 5px 15px;
  color: white;
  margin-bottom: 5px;
  background-color: red;
  border-color: red;
  border-radius: 5px;
}

.p-button-secondary:hover {
  background-color: #CC0033 !important;
}

.text-category-dropdown {
  margin-right: 60px ;
}

.p-fluid.add-edu {
  margin : 20px 30px;
}

.p-inputtext {
  padding: 5px;
}

.p-button-text {
  margin-bottom: 5px;
  margin-left: 5px;
  border-radius: 5px;
}

.button-group {
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
}

.p-button-text.add-edu-confirm {
  padding: 5px 15px;
  color: white;
  margin-bottom: 5px;
  background-color: var(--green2);
  border-radius: 5px;
}

.p-button-text.add-edu-cancel {
  padding: 5px 15px;
  color: white;
  margin-bottom: 5px;
  background-color: red;
  border-color: red;
  border-radius: 5px;
}

.p-dialog-header > div.p-dialog-header-icons > button {
  box-shadow: var(--green4);
}

.p-dialog-header > div.p-dialog-header-icons > button :hover {
  box-shadow: var(--green4);
}

.custom-dialog .p-dialog-footer .p-button {
  margin-right: 10px; 
  margin-bottom: 10px;
}

.p-fileupload .p-fileupload-buttonbar {
  margin: 10px 0px;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  background-color: var(--green2);
  padding: 5px 10px ;
}

.p-fileupload-buttonbar :hover {
  background-color: var(--green3);
}

.add-cert {
  margin-top: 45px;
}

.delete-account-text {
  margin-top: 40px;
}

.setting-group {
  margin: 30px 100px;
}

.setting-profile-text {
  margin: 20px 0px
}

.setting-title {
  margin-left: 50px;
}

.setting-line {
	flex: 1;
	border-top: 1.5px solid ;
  margin-left: 55px;
  margin-right: 55px;
}

.setting-item {
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.form-check-input:checked {
 background-color: #28825A !important;
 border-color: #28825A !important;
}

.p-button .p-button-icon-left {
  display: none;
}
